import '@fontsource-variable/playfair-display';
import '@fontsource/homemade-apple';
import './App.css';
import photo from './photo.jpg';

function App() {
  console.log("Bloody hell. Why are you looking here? Look, I know this is about the worst react page I've ever put together. ")
  console.log("I am an AWS engineer nowadays, I don't really have time for fancy front-end work, I'm sorry.")
  console.log("If you are resizing the windows to see how it scales, good luck. Just know that I'm as disappointed as you are.")
  
  return (
    <div className="App">
      <div className="Container-white">
        <h3>Hi there! It's been a while.</h3>
        <p>We’ve got a bit of catching up to do!</p>
        <p>Last year, we decided that asking our friends and family to spend a fortune to attend a destination wedding was not the type of celebration we wanted.</p>
        <p>We wanted something a bit closer to home,<br /> something that everyone could enjoy.</p>
        <p><em>Much love,</em><br/><em>Michael and Charlotte</em></p>
      </div>
      <div className="Container-yellow">
        <h4>Here’s what you need to know!</h4>
        <p>On Saturday March 16, we’ll be hosting a small reception at:</p>
        <h2>66 Biltofts Road,<br/> Gladfield 4370</h2>
        <p>This is <em>Steepstone</em>, my aunt Philippa’s farm. She has graciously allowed us to host our party here.</p>
        <p><a className="white-button" target="_blank" rel="noreferrer" href="https://forms.gle/SsozR437DwULZfD8A">RSVP</a></p>
      </div>
      <div className="Container-white">
        <img src={photo} alt="Michael and Charlotte. On a boat."/>
        <p>It’s been a long time since I've been home, and I’m looking forward to hearing what you’ve been up to.</p>
      </div>
      <div className="Container-green">
        <h4>Here’s some extra info!</h4>
        <p><b>Dress code:</b> relaxed garden party, nothing terribly fancy. Don't bother with a suit. Also, there might be mud. It is a farm, after all.</p>
        <p>Feel free to turn up late, or leave when you want. People have kids nowadays!</p>
        <p>This will be a <em>laid-back</em> affair. Think shindig, think Bunnings sausage sizzle, not reception.</p>
        <p><b>Catering:</b> Some catering will be provided, i.e. there will be a barbeque. Sit around, drink white wine in the sun, listen to music.</p>
        <p><b>Consider a picnic blanket.</b> BYO drinks if you like, but certainly there will be drinks provided!</p>
        <p></p>
      </div>
      <div className="Container-white">
        <h4>Events for the day.</h4>
        <p><b>12-ish to 1-ish:</b> Guests arrive. Make yourselves comfortable.</p>
        <p><b>Around 2:</b> Michael will quite possibly say a few words. Light refreshments available.</p>
        <p><b>Slightly after 2:</b> Michael has stopped talking <i>(thankfully)</i>, so feel free to get pleasantly tipsy, talk with old friends and enjoy the country air.</p>
        <p><b>After 6:</b> A banjo may come out, signifying the commencement of <em>care-free</em> drinking. Interpret this as you will.</p>
        <p><b>After 10pm:</b> Drinking will continue, but more quietly. We're getting older. </p>
        <p><b>The following morning:</b> A fry-up for any overnight guests. Please let me know if you'd like to hang out this long.</p>
      </div>
      <div className="Container-yellow">
        <h3>Please let me know if you can make it!</h3>
        <p>Email me at <a href="mailto:michael@whitman.io">michael@whitman.io</a>, or send a text or WhatsApp to <a href="tel:+33779290774">+33 779290774</a>.</p>
        <p>Or use this:</p>
        <p><a className="white-button" target="_blank" rel="noreferrer" href="https://forms.gle/SsozR437DwULZfD8A">RSVP</a></p>
      </div>
      <div className="Container-pale">
        <h3>Nearby accommodation</h3>
        <p>If you're coming from out of town, or just expect not to be able to drive home:</p>
        <div className="Hotels">
        <div className="Small">
          <h4>Maryvale Crown Hotel</h4>
          <p>47 Taylor St Maryvale Qld</p>
          <p>Ph 07 46661148</p>
          <p><a href="https://maryvalehotel.com.au">maryvalehotel.com.au</a></p>
        </div>
        <div className="Small">
          <h4>Bestbrook Mountain Resort</h4>
          <p>9790 Cunningham Highway Tregony Qld</p>
          <p>Ph 07 46661282</p>
          <p><a href="https://bestbrook.com.au">bestbrook.com.au</a></p>
        </div>
        <div className="Small">
          <h4>Horse & Jockey Hotel</h4>
          <p>Palmerin Street Warwick Qld</p>
          <p>Ph 07 4661 0600</p>
          <p><a href="https://horseandjockey.com.au">horseandjockey.com.au</a></p>
        </div>
        <div className="Small">
          <h4>Abbey Boutique Hotel</h4>
          <p>8 Locke Street Warwick Qld</p>
          <p>Ph 07 4661 9777</p>
        </div>
        </div>
      </div>
      <div className="Container-white">
        <p>If you're worried that you can't make it, fear not. We'll be in Brisbane the week before and the week after.</p>
        <p>Ping me and we can work something out!</p>
        <p><em>Hope to see you soon!</em></p>
      </div>
    </div>
  );
}

export default App;
